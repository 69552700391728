<template>
    <div class="chat-submit">
        <form class="chat-submit__form" @submit.prevent="handleSubmit">
            <div class="editor">
                <input :id="_uid" ref="uploadInput" type="file" multiple @change="handleUpload" />

                <div class="editor__body">
                    <label :for="_uid" class="editor__attach">
                        <svg-vue icon="paperclip" />
                    </label>

                    <textarea v-model="text" rows="1" placeholder="Введите сообщение..." />

                    <button type="submit">{{ $t("help.chat.send") }}</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import { bytesToMegaBytes } from "@/helpers/bytes"

export default {
    props: {},
    data() {
        return {
            text: "",
            uploader: {
                allowedMime: ["png", "jpg", "jpeg", "webp", "pdf", "mp4"],
                maxSize: "5",
            },
        }
    },
    computed: {},
    methods: {
        async handleSubmit() {
            if (this.text.trim().length >= 1) {
                await this.sendChatMessage({ id: this.$route.params.id, message: this.text })

                this.text = ""
                this.$emit("onSubmit")
            }
        },
        async handleUpload(e) {
            const files = e.target.files

            let resFile = []

            ;[...files].forEach((file) => {
                // limit mime
                if (this.uploader.allowedMime) {
                    const mimeType = file.type ? file.type.split("/")[1] : undefined

                    if (!this.uploader.allowedMime.includes(mimeType)) {
                        this.$swal(this.$t("ui.uploader.badMime"))
                        return false
                    }
                }

                // limit size
                if (this.uploader.maxSize) {
                    const sizeInMb = bytesToMegaBytes(file.size)

                    if (sizeInMb > this.maxSize) {
                        this.$swal(this.$t("ui.uploader.maxSize", { size: this.maxSize }))
                        e.target.value = ""
                        return false
                    }
                }

                resFile.push(file)
            })

            if (resFile.length) {
                await this.sendChatMessage({ id: this.$route.params.id, files: resFile })

                this.$emit("onSubmit")
            }

            e.target.value = ""
        },
        ...mapActions("ticket", ["sendChatMessage"]),
    },
}
</script>

<style lang="scss" scoped>
.chat-submit {
    &__form {
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            visibility: hidden;
        }
    }
}

.editor {
    padding-left: 10px;

    &__attach {
        flex: 0 0 auto;
        padding: 10px 5px;
        font-size: 0;
        line-height: 1;
        color: var(--color-silver);
        cursor: pointer;
        transition: color 0.25s ease-in-out;
        &:hover {
            color: var(--color-white);
        }
    }
    &__body {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        flex: 1 1 auto;
        textarea {
            padding: 10px 5px;
            display: block;
            width: 100%;
            background: transparent;
            font-weight: 400;
            font-size: 14px;
            line-height: calc(21 / 14);
            color: var(--color-silver);
            background: transparent;
            resize: none;
        }

        button {
            padding: 10px;
            font-weight: 600;
            font-size: 14px;
            line-height: calc(21 / 14);
            background: transparent;
            box-shadow: none;
            border-radius: 0;
            border: 0;
            color: var(--color-silver);
            transition: color 0.25s ease-in-out;
            &:hover {
                color: var(--color-yellow);
            }
        }
    }
}
</style>
