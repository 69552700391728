<template>
    <div class="messages" ref="scroller">
        <div class="messages__group" v-for="(group, idx) in groups" :key="idx">
            <div class="messages__date">
                <span>{{ group.date }}</span>
            </div>
            <ChatMessage v-for="message in group.messages" :message="message" :key="message.id" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"
import groupBy from "lodash/groupBy"
import ChatMessage from "@/components/Help/Chat/Message.vue"
import throttle from "lodash/throttle"

export default {
    components: { ChatMessage },
    data() {
        return {
            loading: false,
        }
    },
    created() {
        this.scrollListWithThrottle = throttle(this.handleListScroll, 300)
    },

    computed: {
        groups() {
            const formatMask = "DD MMM"

            const dates = this.messages.map((x) => this.$moment(x.created_at, "DD-MM-YYYY").format(formatMask))
            const datesGrouped = Object.keys(groupBy(dates))

            return datesGrouped.map((date) => {
                const messages = this.messages.filter(
                    (message) => this.$moment(message.created_at, "DD-MM-YYYY").format(formatMask) === date
                )
                return {
                    date,
                    messages,
                }
            })
        },
        ...mapGetters("ticket", ["messages", "messagesMeta"]),
    },
    mounted() {
        this.$refs.scroller.addEventListener("scroll", this.scrollListWithThrottle, false)
        document.addEventListener("click", this.hideViewerOnClick, false)

        setTimeout(() => {
            this.autoScroll()
        }, 500)
    },
    beforeDestroy() {
        this.$refs.scroller.removeEventListener("scroll", this.scrollListWithThrottle, false)
        document.removeEventListener("click", this.hideViewerOnClick, false)
    },
    methods: {
        async handleListScroll() {
            if (!this.$refs.scroller || this.loading) return

            const { current_page, total_pages } = this.messagesMeta
            if (current_page >= total_pages) return

            const { scrolled } = this.hasScrolledToBottom()
            if (!scrolled) return

            this.loading = true
            await this.getMessages({
                id: this.$route.params.id,
                page: current_page + 1,
            })
            this.loading = true
        },
        hasScrolledToBottom() {
            const { height } = this.$refs.scroller.getBoundingClientRect()
            const top = this.$refs.scroller.scrollTop
            const scrollHeight = this.$refs.scroller.scrollHeight
            const bottomScroll = scrollHeight - top
            const scrollRest = Math.abs(bottomScroll) - height

            return {
                scrollHeight: scrollHeight,
                scrolled: scrollRest <= 200,
            }
        },
        autoScroll() {
            const { scrollHeight, scrolled } = this.hasScrolledToBottom()

            if (scrolled) {
                this.$refs.scroller.scrollTo({
                    top: scrollHeight,
                    behavior: "smooth",
                })
            }
        },
        hideViewerOnClick(e) {
            if (e.target.closest(".viewer-canvas")) {
                if (window.$viewer) {
                    window.$viewer.hide()
                }
            }
        },
        ...mapActions("ticket", ["getMessages"]),
    },
    watch: {
        messages(newMessages) {
            setTimeout(() => {
                this.autoScroll()
            }, 300)
        },
    },
}
</script>

<style lang="scss" scoped>
.messages {
    &__group {
        margin: 15px 0;
    }
    &__date {
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 14px;
        line-height: calc(21 / 14);
        text-align: center;
        color: var(--color-silver);
    }
}
</style>
