<template>
    <div v-if="!loading">
        <HelpTicket />
    </div>

    <div v-else>
        <UiLoader theme="block" :loading="true" />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import pageReadyByConnect from "@/mixin/pageReadyByConnect"

import HelpTicket from "@/components/Help/Ticket.vue"

export default {
    components: {
        HelpTicket,
    },
    mixins: [pageReadyByConnect],
    data() {
        return {
            loading: true,
            error: false,
        }
    },
    computed: {
        ...mapGetters("crypto", ["getAccount", "isConnected"]),
    },
    async mounted() {
        if (this.isConnected) {
            await this.pageReady()
        }
    },
    methods: {
        async pageReady() {
            const [regErr, regParent] = await this.checkRegistration(true)
            if (regErr) {
                this.$swal(this.$t("errors.registrationError"))
                this.$router.push({ name: "signup" })
                return
            }

            await this.fetchTicket()
        },
        async fetchTicket() {
            this.loading = true
            this.error = false

            try {
                await this.getTicket({ id: this.$route.params.id, lang: this.$i18n.locale })
            } catch (err) {
                this.$swal(this.$t("help.ticket.notFound"))
                this.error = this.$t("help.ticket.notFound")
                this.$router.push({ name: "help" })
            }

            this.loading = false
        },
        ...mapActions("user", ["guessUser"]),
        ...mapActions("crypto", ["checkRegistration"]),
        ...mapActions("ticket", ["getTicket"]),
    },
}
</script>
<style lang="scss" scoped>
.main {
    width: 100%;
    @media screen and (max-width: 960px) {
        padding: 0 20px;
    }
}
</style>
