<template>
    <div class="message" :class="[isIncoming && 'outgoing']" :data-id="message.id">
        <div class="message__wrapper">
            <template>
                <div class="message__title" v-if="isSupportUser">{{ message.author.name }}, Служба поддержки Force</div>
                <div class="message__text" v-html="message.message" />
            </template>
            <MessageFile v-for="(file, idx) in files" :file="file" :key="idx" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { isSameAccount } from "@/helpers/user"
import MessageFile from "./File.vue"

export default {
    components: { MessageFile },
    props: {
        message: Object
    },
    computed: {
        isSupportUser() {
            return this.message.author && this.message.author.role === 2
        },
        isIncoming() {
            return isSameAccount(this.message.author.account, this.getAccount)
        },
        files() {
            return this.message.files
        },

        ...mapGetters("crypto", ["getAccount"])
    }
}
</script>

<style lang="scss" scoped>
.message {
    margin-bottom: 10px;
    display: flex;
    padding-right: 20px;

    &:last-child {
        margin-bottom: 0;
    }
    &__wrapper {
        position: relative;
        z-index: 1;
        overflow: hidden;
        min-width: 1px;
        width: 100%;
        max-width: 368px;
        padding: 15px 20px;
        background: var(--color-cinder);
        border-radius: 0px 20px 20px 20px;
    }
    &__title {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: calc(21 / 14);
    }
    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: calc(21 / 14);
    }

    &.outgoing {
        padding-right: 0;
        padding-left: 20px;
        justify-content: flex-end;
        .message__wrapper {
            background: var(--color-everglade);
            border-radius: 20px 0px 20px 20px;
        }
    }
    // &.is-file{}
    &.is-image {
        .message__wrapper {
            padding: 0;
        }
    }
}
</style>
