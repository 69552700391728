<template>
    <div class="message__file" @click="handleFileClick">
        <div v-if="isFileImage" class="message__file-image">
            <img :src="fileImageUrl" />
        </div>
        <div v-else class="message__file-attachment">
            <div class="message__file-icon">
                <svg-vue icon="file" />
            </div>
            <div class="message__file-title">
                {{ message.file.file }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"
import { isSameAccount } from "@/helpers/user"

export default {
    props: {
        file: Object,
    },
    computed: {
        isFileImage() {
            if (this.file) {
                const splits = this.file.file.split(".")
                const ext = splits[splits.length - 1]

                return ["jpg", "png"].includes(ext)
            }
            return false
        },
        fileImageUrl() {
            return this.file.file
        },
        ...mapGetters("crypto", ["getAccount"]),
    },

    methods: {
        handleFileClick() {
            if (!this.isFileImage) {
                window.open(this.file.file)
            } else {
                window.$viewer = this.$viewerApi({
                    options: {
                        navbar: false,
                        toolbar: false,
                        movable: false,
                        rotatable: false,
                        zoomOnWheel: false,
                        title: false,
                        button: false,
                        zoomable: false,
                    },
                    images: [this.file.file],
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.message {
    &__file-attachment {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: color 0.25s ease;
        &:hover {
            color: rgb(white, 0.7);
        }
    }
    &__file-icon {
        font-size: 0;
        line-height: 1;
        margin-right: 7px;
    }
    &__file-title {
        font-weight: 600;
        font-size: 14px;
        line-height: calc(21 / 14);
    }
    &__file-image {
        font-size: 0;
        line-height: 1;
        cursor: pointer;
        padding: 1px; // todo border
        img {
            max-width: 100%;
            transition: filter 0.25s ease-out;
        }
        &:hover {
            img {
                filter: grayscale(0.2);
            }
        }
    }
}
</style>
